import React from 'react';

const DashboardIcon = ({ color }) => (
    <svg className='active_icon' width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.875 8.1582V15.4915C17.875 16.2209 17.5853 16.9204 17.0695 17.4361C16.5538 17.9518 15.8543 18.2415 15.125 18.2415H6.875C6.14565 18.2415 5.44618 17.9518 4.93046 17.4361C4.41473 16.9204 4.125 16.2209 4.125 15.4915V8.1582" stroke={color} stroke-width="1.5" stroke-linejoin="round" />
    <path d="M19.25 9.07512L17.875 8.15845L12.375 4.49178L11.5042 3.91428C11.3545 3.81572 11.1792 3.76318 11 3.76318C10.8208 3.76318 10.6455 3.81572 10.4958 3.91428L9.625 4.49178L4.125 8.15845L2.75 9.07512" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M9.16675 15.4917H12.8334" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg>
);

export default DashboardIcon