import React, { useState, useEffect } from 'react';
import constants from '../../../constants/constants';
import axiosInstance from '../../../constants/axiosInstance';

const ProjectOverviewGanttChart = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [projectRecords, setProjectRecords] = useState();
    const [tasks, setTasks] = useState();
    const [monthList, setMonthList] = useState();

    const formatDate = (value) => {
        var date = value ? new Date(value) : null;
        return date?.toLocaleDateString('en-US', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
        });
    }

    // Generate month data
    const generateMonthData = (start, end) => {
        const months = [];
        const current = new Date(start.getFullYear(), start.getMonth(), 1);
        while (current <= end) {
            months.push(current.toLocaleString("default", { month: "long", year: "numeric" }));
            current.setMonth(current.getMonth() + 1);
        }
        return months;
    };

    useEffect(() => {
        if (props && props?.toGantt) {
            const taskItem = props?.toGantt;
            console.log(taskItem);

            // Collect all valid dates from the task items
            const allDates = taskItem.flatMap(task => task.start && task.end && [
                new Date(task.start),
                new Date(task.end)
            ]
            ).filter((item) => item);
            console.log(allDates);

            const minDate = new Date(Math.min(...allDates.map(date => date.getTime())));
            const maxDate = new Date(Math.max(...allDates.map(date => date.getTime())));

            const monthData = generateMonthData(minDate, maxDate);
            setMonthList(monthData);
            setTasks(taskItem);
        }
    }, [props]);

    const TaskListTable = () => {
        return (
            <div style={{ border: "1px solid #dfe1e5", borderWidth: "0 0 1px 1px", width: "428px" }}>
                {tasks?.map((item, i) => {
                    return (
                        <tr key={item.id} style={{ display: "flex", alignItems: "center", cursor: "auto", padding: 10, border: "1px solid #dfe1e5" }}>
                            <td style={{ width: "50%" }} className='gt_task_name gt_task '>{item.name}</td>
                            <td style={{ width: "25%" }} className='gt_date'>{formatDate(item.start)}</td>
                            <td style={{ width: "25%" }} className='gt_date'>{formatDate(item.end)}</td>
                        </tr>
                    );
                })}
            </div>
        );
    };

    const getWeekDateRange = (month, weekIndex) => {
        const [monthName, year] = month.split(" ");
        const monthIndex = new Date(`${monthName} 1, ${year}`).getMonth(); // 0-based index
        const startOfMonth = new Date(year, monthIndex, 1);

        // Calculate start and end dates for the given week
        const weekStart = new Date(startOfMonth);
        weekStart.setDate(weekStart.getDate() + weekIndex * 7);

        const weekEnd = new Date(weekStart);
        weekEnd.setDate(weekEnd.getDate() + 6);

        // Ensure the week doesn't exceed the month's last day
        const daysInMonth = new Date(year, monthIndex + 1, 0).getDate();
        const endOfMonth = new Date(year, monthIndex, daysInMonth);

        return [
            weekStart > endOfMonth ? endOfMonth : weekStart,
            weekEnd > endOfMonth ? endOfMonth : weekEnd,
        ];

    };

    const TaskValueTable = () => {
        return (
            <div>
                {tasks?.map((item, i) => {
                    return (
                        <tr key={item.id} style={{ display: "flex", alignItems: "center", cursor: "auto" }}>
                            {monthList?.map((month, index) => {
                                const weeks = generateWeeksForMonth(month);
                                const taskStart = new Date(item.start);
                                const taskEnd = new Date(item.end);
                                return (
                                    <td key={`${item.id}-${index}`}
                                        className="month-cell p-0"
                                        style={{
                                            minWidth: `${50 * weeks.length}px`, height: "38.21px",
                                            display: "flex", // Allow week-based styling
                                            border: "1px solid #dfe1e5"
                                        }}
                                    >
                                        {weeks.map((week, weekIndex) => {
                                            // Calculate the week's date range
                                            const [weekStart, weekEnd] = getWeekDateRange(month, weekIndex);
                                            const isWeekInRange =
                                                weekStart <= taskEnd && weekEnd >= taskStart;

                                            return (
                                                <div
                                                    key={`${item.id}-${month}-${weekIndex}`}
                                                    style={{
                                                        flex: 1, // Equal width for all weeks
                                                        backgroundColor: isWeekInRange
                                                            ? item?.styles?.backgroundColor // Highlight color for weeks in task duration
                                                            : "transparent",
                                                        borderRight: "1px solid #dfe1e5",
                                                        height: "100%",
                                                    }}
                                                >
                                                    {isWeekInRange ? "" : ""}
                                                </div>
                                            );
                                        })}
                                    </td>
                                )
                            })}
                        </tr>
                    );
                })}
            </div>
        );
    };

    // Helper function to format date to "MMM YYYY"
    const formatDateToMonth = (date) => {
        return new Date(date).toLocaleString("default", { month: "long", year: "numeric" });
    };
    console.log(monthList);

    const generateWeeksForMonth = (month) => {
        // Parse the month string into a Date object
        const [monthName, year] = month.split(' ');
        const monthIndex = new Date(`${monthName} 1, ${year}`).getMonth(); // 0-based index

        // Get number of days in the month
        const daysInMonth = new Date(year, monthIndex + 1, 0).getDate();

        // Generate weeks
        const weeks = [];
        for (let i = 1; i <= daysInMonth; i += 7) {
            const weekNumber = Math.ceil(i / 7);
            weeks.push(`Week ${weekNumber}`);
        }

        return weeks;
    };

    return (
        <>
            <div className='gantt-container overflow-hidden'>
                <div className='flex'>
                    <div style={{ border: "1px solid #dfe1e5", borderWidth: "1px 0 1px 1px", width: "428px" }}>
                        <tr className='flex align-items-center' style={{
                            marginBottom: -2, fontWeight: "bold", paddingLeft: 10, height: 76.67,backgroundColor:'#F8F8F8'
                        }}>
                            <td className='h-full px-1 py-3 gt_task_name group-project-name' style={{ width: "50%", alignContent: "center" }}>Name</td>
                            <td className='h-full px-1 py-3 group-project-name' style={{ width: "25%", alignContent: "center" }}>From</td>
                            <td className='h-full px-1 py-3 group-project-name' style={{ width: "25%", alignContent: "center" }}>To</td>
                        </tr>
                        <TaskListTable />
                    </div>
                    <div className='gantt-value-container overflow-auto' style={{ border: "1px solid #dfe1e5", borderWidth: "1px 1px 1px 1px" }}>
                        <div style={{ width: "max-content" }}>
                            <tr className='flex align-items-center' style={{
                                marginBottom: -2, fontWeight: "bold"
                            }}>
                                {monthList?.map((month) => {
                                    const weeks = generateWeeksForMonth(month);
                                    return (
                                        <td colSpan={weeks.length} className='h-full px-1 py-3 text-center gt_month' style={{ borderRight: "1px solid #dfe1e5", minWidth: `${50 * weeks.length}px`, backgroundColor:'#F8F8F8' }}>{month}</td>
                                    )
                                })}
                            </tr>

                            {/* Weeks Row */}
                            <tr className='flex align-items-center'>
                                {monthList?.map((month) => {
                                    const weeks = generateWeeksForMonth(month);
                                    return (
                                        <td
                                            key={`${month}-weeks`} className='p-0'
                                            // colSpan={weeks.length}
                                            style={{ minWidth: `${50 * weeks.length}px` }}
                                        >
                                            <div className='w-full text-center flex justify-content-between' style={{ border: "1px solid #dfe1e5", borderWidth: "1px 0px 0px 0px",backgroundColor:'#F8F8F8' }}>
                                                {weeks.map((week, index) => (
                                                    <span key={`${month}-week-${index}`} className='h-full px-1 py-3 text-center gt_week' style={{ border: "1px solid #dfe1e5", borderWidth: "0px 1px 0px 0px" }}>{week}</span>
                                                ))}
                                            </div>
                                        </td>
                                    );
                                })}
                            </tr>
                            <TaskValueTable />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProjectOverviewGanttChart;