// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p-fluid .p-calendar{
    width: 100%;
}
.p-tabmenu .p-tabmenu-nav {
    background: transparent;
    border: 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link{
    border: 0;
    padding: 6px 10px;
    color: #03030A;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.008px;
    background: transparent;
    border-radius: 5px;
    margin: 0;
    height: auto;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link{
    border-radius: 5px;
background: #323232;
color: #E6FB2D;
border-color: transparent;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus-visible{
    box-shadow: inset 0 0 0 0rem #C7D2FE;
}

.p-datatable .p-datatable-tbody > tr > td{
border-bottom: 1px solid #EAEAEA;
padding: 8px;
}

.p-dialog .p-dialog-header{
    border-radius: 8px 8px 0px 0px;
border: 1px solid #FFF;
background: #F8F8F8;
color: #03030A;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
padding: 18px;
}
.p-dialog .p-dialog-content{
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
}
.p-dialog .p-icon{
    color: black;
}

/* input box */
`, "",{"version":3,"sources":["webpack://./src/override.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;AACA;IACI,uBAAuB;IACvB,SAAS;AACb;AACA;IACI,SAAS;IACT,iBAAiB;IACjB,cAAc;IACd,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,uBAAuB;IACvB,uBAAuB;IACvB,kBAAkB;IAClB,SAAS;IACT,YAAY;AAChB;;AAEA;IACI,kBAAkB;AACtB,mBAAmB;AACnB,cAAc;AACd,yBAAyB;AACzB;AACA;IACI,oCAAoC;AACxC;;AAEA;AACA,gCAAgC;AAChC,YAAY;AACZ;;AAEA;IACI,8BAA8B;AAClC,sBAAsB;AACtB,mBAAmB;AACnB,cAAc;AACd,kBAAkB;AAClB,eAAe;AACf,kBAAkB;AAClB,gBAAgB;AAChB,mBAAmB;AACnB,aAAa;AACb;AACA;IACI,oCAAoC;AACxC;AACA;IACI,YAAY;AAChB;;AAEA,cAAc","sourcesContent":[".p-fluid .p-calendar{\n    width: 100%;\n}\n.p-tabmenu .p-tabmenu-nav {\n    background: transparent;\n    border: 0;\n}\n.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link{\n    border: 0;\n    padding: 6px 10px;\n    color: #03030A;\n    font-family: Inter;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: normal;\n    letter-spacing: 0.008px;\n    background: transparent;\n    border-radius: 5px;\n    margin: 0;\n    height: auto;\n}\n\n.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link{\n    border-radius: 5px;\nbackground: #323232;\ncolor: #E6FB2D;\nborder-color: transparent;\n}\n.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus-visible{\n    box-shadow: inset 0 0 0 0rem #C7D2FE;\n}\n\n.p-datatable .p-datatable-tbody > tr > td{\nborder-bottom: 1px solid #EAEAEA;\npadding: 8px;\n}\n\n.p-dialog .p-dialog-header{\n    border-radius: 8px 8px 0px 0px;\nborder: 1px solid #FFF;\nbackground: #F8F8F8;\ncolor: #03030A;\nfont-family: Inter;\nfont-size: 16px;\nfont-style: normal;\nfont-weight: 600;\nline-height: normal;\npadding: 18px;\n}\n.p-dialog .p-dialog-content{\n    padding: 1.5rem 1.5rem 1.5rem 1.5rem;\n}\n.p-dialog .p-icon{\n    color: black;\n}\n\n/* input box */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
