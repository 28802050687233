import React, { useEffect } from "react";
import { TabMenu } from 'primereact/tabmenu';
import { useStoreActions, useStoreState } from "easy-peasy";
import AddNewClients from "./AddNewClients";
import AllClientsList from "./AllClientsList";
import { Divider } from 'primereact/divider';

const ClientRegistry = () => {
    const setActiveIndex = useStoreActions((actions) => actions.tabModel.setActiveIndex);
    const activeIndex = useStoreState((actions) => actions.tabModel.activeIndex);

    const items = [
        { label: 'New' },
        { label: 'Customers' },
    ];

    useEffect(() => {
        setActiveIndex(0);
    }, []);

    return (
        <>
            <h3 className="PS-title m-0">Customer Registry</h3>
            <Divider />
            <div className="Trainee-wrapper">
                <div className="flex justify-content-between master-tab-inner">
                    <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
                </div>
                {activeIndex === 0 && <AddNewClients />}
                {activeIndex === 1 && <AllClientsList />}
            </div>
            
        </>
    )
}
export default ClientRegistry;