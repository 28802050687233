import React from 'react';
import { useRef } from 'react';
import { Toast } from 'primereact/toast';
import { useStoreState } from 'easy-peasy';

const ProjectStatusCard = () => {
    const toast = useRef(null);
    const projectDashboardData = useStoreState((actions) => actions.tabModel.projectDashboardData);

    return (
        <>
            <Toast ref={toast} />
            <div className='my-3 grid px-2' style={{border: '1px solid #F0F0F0', borderRadius:'10px'}}>
                <div className='col-12 md:col-6 lg:col-4'>
                    <div className='flex p-2 px-2' style={{justifyContent: 'space-between',alignItems:'center'}}>
                            <div className='flex'>
                            <div className='p_blue'>
                            {`${projectDashboardData?.sumOfSubTasksCount === 0 ? 0 : ((projectDashboardData?.openCount / projectDashboardData?.sumOfSubTasksCount) * 100).toFixed(0)}%`} 
                            </div>
                        <div className='project-staus-value-color-blue mx-3'>
                            <h5 className='projects-title mb-0 my-2'>Open</h5>
                            <p className='projects-status-value my-3'>{projectDashboardData?.openCount} <span className='projects-status-value-out_of'>/ {projectDashboardData?.sumOfSubTasksCount}</span></p>
                        </div>
                        </div>
                        <div className='flex align-items-center flex-column'>
                        <span class="border-circle p-1  font-bold" style={{background : "#DCDCDC"}}></span>
                        <span class="border font-bold" style={{background : "#DCDCDC" ,height:'65px', width:'1px'}}></span>
                        <span class="border-circle p-1 font-bold" style={{background : "#DCDCDC"}}></span>
                        </div>
                    </div>
                </div>
                <div className='col-12 md:col-6 lg:col-4'>
                    <div className='flex p-2' style={{justifyContent: 'space-between', alignItems:'center'}}>
                        <div className='flex'>
                            <div className='p_purple'>
                            {`${projectDashboardData?.sumOfSubTasksCount === 0 ? 0 : ((projectDashboardData?.inProgressCount / projectDashboardData?.sumOfSubTasksCount) * 100).toFixed(0)}%`}
                            </div>
                        <div className='project-staus-value-color-purple mx-3'>
                            <h5 className='projects-title mb-0 my-2'>Inprogress</h5>
                            <p className='projects-status-value my-3'>{projectDashboardData?.inProgressCount} <span className='projects-status-value-out_of'>/ {projectDashboardData?.sumOfSubTasksCount}</span></p>
                        </div>
                    </div>
                        <div className='flex align-items-center flex-column'>
                        <span class="border-circle p-1  font-bold" style={{background : "#DCDCDC"}}></span>
                        <span class="border font-bold" style={{background : "#DCDCDC" ,height:'65px', width:'1px'}}></span>
                        <span class="border-circle p-1 font-bold" style={{background : "#DCDCDC"}}></span>
                        </div>
                    </div>
                    </div>
                    
                <div className='col-12 md:col-6 lg:col-4'>
                    <div className='flex p-3'style={{ alignItems:'center'}}>
                            <div className='p_green'>
                            {`${projectDashboardData?.sumOfSubTasksCount === 0 ? 0 : ((projectDashboardData?.completedCount / projectDashboardData?.sumOfSubTasksCount) * 100).toFixed(0)}%`}
                            </div>
                        <div className='project-staus-value-color-green mx-3'>
                            <h5 className='projects-title mb-0 my-2'>Completed</h5>
                            <p className='projects-status-value my-3'>{projectDashboardData?.completedCount} <span className='projects-status-value-out_of'>/ {projectDashboardData?.sumOfSubTasksCount}</span></p>
                        </div>
                    </div></div>
            </div>
        </>
    );
}

export default ProjectStatusCard;