import React, { useEffect, useState } from "react";
import "dhtmlx-gantt/codebase/dhtmlxgantt.css";
import gantt from "dhtmlx-gantt";
import '../../../assets/css/WeeklyGanttChart.css'

const WeeklyGanttChart = (props) => {
    useEffect(() => {
        // Initialize Gantt chart only once
        gantt.init("gantt-chart");

        // Configure scales
        gantt.config.scales = [
            { unit: "month", step: 1, format: "%F %Y" }, // Month scale (e.g., January 2025)
            { unit: "week", step: 1, format: "Week %W" }, // Week scale (e.g., Week 1)
        ];

        // Configure Gantt columns
        gantt.config.columns = [
            { name: 'text', label: 'Task Name', width: 200, tree: true },
            { name: 'start_date', label: 'Start Date', width: 100 },
            { name: 'end_date', label: 'End Date', width: 100 }
        ];

        // Customize header styles using JavaScript
        gantt.config.scale_height = 50; // Set height of the header

        // Make all header cells bold
        gantt.templates.scale_cell_class = () => {
            return "bold-header";
        };

        // Disable task interactions (read-only mode)
        gantt.config.readonly = true;

        // Disable specific drag interactions
        gantt.config.drag_move = false;  // Disable task moving
        gantt.config.drag_progress = false;  // Disable task progress dragging
        gantt.config.drag_resize = false;  // Disable task resizing

        // Disable the plus icon by preventing the expansion of tasks (no subtasks)
        gantt.config.open_tree_initially = false;
        gantt.config.subtask_opened = false; // Explicitly disable subtasks

        gantt.templates.task_text = () => {
            return "";  // Return an empty string to remove the text inside the task bars
        };
    }, []);

    useEffect(() => {
        if (props && props.toGantt) {
            const taskItem = props.toGantt;

            // Clear existing tasks from the Gantt chart
            gantt.clearAll();

            // Transform tasks data(Load tasks)
            const transformedData = taskItem.map((item) => ({
                id: item.id,
                text: item.name || "Unnamed Task",
                start_date: new Date(item.start), // Format start date to YYYY-MM-DD
                duration: item.duration,
                progress: 0, // Default progress to 0
                color: item.styles.backgroundColor, // Apply background color as inline style
                end_date: new Date(item.end), // Format end date to YYYY-MM-DD
            }));

            // Parse tasks into the Gantt chart
            gantt.parse({
                data: transformedData,
            });

            // Update Gantt height based on task count
            updateGanttHeight(transformedData.length);
        }
    }, [props]);

    // Dynamic height based on data
    const updateGanttHeight = (taskCount) => {
        const ganttChart = document.getElementById("gantt-chart");
        if (ganttChart) {
            // Calculate height: 50px per task row, with a minimum of 200px and a maximum of 500px
            const height = Math.max(100, Math.min((40 * taskCount) + 50, 500)); // 100px for header
            ganttChart.style.height = `${height}px`;
        }
    };

    return (
        <>
            <div id="gantt-chart" style={{ width: "100%" }} />
        </>
    );
};

export default WeeklyGanttChart;