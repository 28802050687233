import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { useForm } from "react-hook-form";
import React, { useState, useRef, useEffect } from "react";
import profile from "../images/image001.png";
import constants from "../constants/constants";
import axios from "axios";
import { useHistory } from 'react-router-dom';
import { useStoreActions, useStoreState } from "easy-peasy";
import { useLocation } from 'react-router-dom';
import Logo from '../images/Logo (2).png';
import backgroundImage from '../images/RectangleCopy.png';

const ForgotPassword = () => {
  const history = useHistory();
  const toast = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const setUser = useStoreActions((actions) => actions.loginModel.setUser);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
   const user = useStoreState((actions) => actions.loginModel.user);
   const location = useLocation();
   const userId = location.state?.userId || localStorage.getItem("userId");

  const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);

  const goto = (to) => {
    history.replace(to);
  };

  const { register,  watch, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = (data) => {
    const payload = {
        newPassword: data.confirmPassword,
    }
    setIsLoading(true);
    axios.patch(constants.URL.FORGOT_PASSWORD + userId , payload)
      .then((resp) => {
        setUser(resp?.data?.data);
        goto("/app/defaultnav");
      }).catch((e) => {
        toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
        console.error(e);
      }).finally(() => {
        setIsLoading(false);
      })
  }

  return (
    <div>
    <Toast ref={toast} />

    <div className="login_container">
        <div className="col-12 md:col-6 flex justify-content-center align-items-center text-align-center">
          <div className="w-full justify-content-center align-items-center text-align-center flex">
        <div className="w-full lg:w-6">
          <div className="mb-5 pb-4">
            {/* <img src={profile} alt="profile" className="" /> */}
          </div>
          <div className="text-left mb-5">
            <div className="head text-3xl mb-3">Set New Password</div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="login_error_msg">
            <div>
              <div className="">
                <label htmlFor="password" className="block text-800 mb-3">New Password</label>
                <div className="relative">
                  <InputText id="password" type={showPassword ? "text" : "password"} className="w-full mb-4"
                    defaultValue={""}
                    {...register("password", {
                      required: "Password is required"
                    })}
                    style={{backgroundColor:'#FFFFFF', color:'#050505'}}
                  />
                  <span className="absolute eye-icon-position cursor-pointer" onClick={togglePasswordVisibility}>
                    {showPassword ? (
                      <i className="pi pi-eye-slash" style={{ color: '#708090', fontSize: "16px" }}></i>
                    ) : (
                      <i className="pi pi-eye" style={{ color: '#708090', fontSize: "16px" }}></i>
                    )}
                  </span>
                </div>
                {errors?.password && <p>{errors.password.message}</p>}
                              </div>
                              <div className="">
                <label htmlFor="confirmPassword" className="block text-800 mb-3">Confirm Password</label>
                <div className="relative">
                  <InputText id="confirmPassword" type={showConfirmPassword ? "text" : "Password"} className="w-full mb-4"
                    defaultValue={""}
                    {...register("confirmPassword", {
                      required: "Password is required",
                      validate: (value) =>
              value === watch("password") || "Passwords do not match",
                    })}
                    style={{backgroundColor:'#FFFFF', color:'#050505'}}
                  />
                  <span className="absolute eye-icon-position cursor-pointer" onClick={toggleConfirmPasswordVisibility}>
                    {showConfirmPassword ? (
                      <i className="pi pi-eye-slash" style={{ color: '#708090', fontSize: "16px" }}></i>
                    ) : (
                      <i className="pi pi-eye" style={{ color: '#708090', fontSize: "16px" }}></i>
                    )}
                  </span>
                </div>
                {errors?.confirmPassword && <p className="text-white-500">{errors.confirmPassword.message}</p>}
                              </div>
              {/* <div className="login_btn"> */}
                <Button label="Reset Password" disabled={isLoading} className="w-full my-3 login-btn" 
                 style={{backgroundColor: "#E6FB2D", color: "#323232",transition: "none",}}/>
              {/* </div> */}
            </div>
          </form>
          <div className="text-center email_login mt-4 " onClick={() => history.push('/')}>
              Login   
            </div>
        </div>
      </div>
      </div>
      
      <div className="md:col-6 hidden md:flex p-3" style={{ height: "100%" }}>
            <div class="ls-admin_bg flex justify-content-center align-items-center text-align-center">
              <div className="flex justify-content-center align-items-center text-align-center">
                {/* <span><img src={Logo} alt="logo" className="" /></span> */}
              </div>
            </div>
        </div>
    </div>
  </div>
);
};

export default ForgotPassword;
