import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import constants from '../../../constants/constants';
import "../../../assets/css/Project.css"
import { Button } from "primereact/button";
import getHeaders from '../../../controllers/utils';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import AddProjects from './AddProjects';
import back from "../../../images/back.svg";
import { ProgressBar } from 'primereact/progressbar';
import view from '../../../images/projects/Eye.svg';
import pencil from '../../../images/projects/Pencil.svg';
import trash from '../../../images/projects/Trash.svg';
import ProjectDetails from './ProjectDetails';
import { FilterMatchMode} from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Controller, useForm } from 'react-hook-form';
import axiosInstance from '../../../constants/axiosInstance';
import { Calendar } from 'primereact/calendar';

const Projects = () => {
    const toast = useRef(null);
    const [records, setRecords] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const setAddProjects = useStoreActions((actions) => actions.projectModel.setAddProjects);
    const addProjects = useStoreState((actions) => actions.projectModel.addProjects);
    const projectViewScreen = useStoreState((actions) => actions.projectModel.projectViewScreen);
    const setProjectViewDatas = useStoreActions((actions) => actions.projectModel.setProjectViewDatas);
    const setProjectViewScreen = useStoreActions((actions) => actions.projectModel.setProjectViewScreen);
    const setProjectEditValue = useStoreActions((actions) => actions.projectModel.setProjectEditValue);
    const projectEditValue = useStoreState((actions) => actions.projectModel.projectEditValue);
    const subTaskProjectName = useStoreState((actions) => actions.projectModel.subTaskProjectName);
    const setSubTaskProjectName = useStoreActions((actions) => actions.projectModel.setSubTaskProjectName);
    const fromDashboard = useStoreState((actions) => actions.projectModel.fromDashboard);
    const setFromDashboard = useStoreActions((actions) => actions.projectModel.setFromDashboard);

    useEffect(() => {
        setIsLoading(true);
        getAllProjectsData();
        setProjectViewScreen(false);
        setProjectEditValue(null);
        setAddProjects(false);
        getUserList();
        getClientData();
    }, []);

    useEffect(() => {
        getAllProjectsData();
    }, [!projectViewScreen]);
    
useEffect(() => { 
    if(subTaskProjectName && fromDashboard){
    if (subTaskProjectName && Array.isArray(records)) {
        // Find the project object where projectName matches subTaskProjectName
        const matchedProject = records.find(project => project.projectName === subTaskProjectName);

        // If a match is found, send it to handleView
        if (matchedProject) {
            handleView(matchedProject);
        } 
    }
}
}, [subTaskProjectName, records]);
  

    // Callback function to handle data from the child
    const handleChildData = (data) => {
        getAllProjectsData();
    };

    const getAllProjectsData = () => {
        axiosInstance.get(constants.APQP_UPL.PROJECTS)
            .then((resp) => {
                setRecords(resp?.data?.data);
            }).catch((e) => {
                console.error(e);
            }).finally(() => {
                setIsLoading(false);
            })
    }

    const handleView = (item) => {
        setProjectViewDatas(item);
        setProjectViewScreen(true);
    }
        
    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    }

    const handleStart = (item, value) => {
        let isValid = true;
        let invalidSubtasks = [];

        if (item?.projectStatus === "Open") {
            item.taskHeader_id.forEach(taskHeader => {
                taskHeader.subTasks.forEach(subTask => {
                    if (!subTask.startDate || !subTask.endDate) {
                        isValid = false;
                        invalidSubtasks.push(subTask.subTaskName);
                    }
                });
            });
        }

        if (!isValid) {
            toast.current.show({ severity: "error", summary: "Failure", detail: `Subtasks ${invalidSubtasks.join(', ')} require start and end dates` });
            return;
        }

        if (item?.taskHeader_id == 0) {
            toast.current.show({ severity: "error", summary: "Failure", detail: `There are no Task to start this project` });
            return;
        }
        
        const payload = {
            projectStatus: value,
        }
        setIsLoading(true);
        axiosInstance.patch(constants.APQP_UPL.PROJECTS + "/" + item?._id, payload)
            .then((resp) => {
                getAllProjectsData();
                const msg = item?.projectStatus === "Open" ? "Started" : item?.projectStatus === "In-Progress" ? "Hold" : "Re-Started"
                toast.current.show({ severity: "success", summary: "Success", detail: `Project ${msg} Successfully`, life: 3000 });
            }).catch((e) => {
                toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
                console.error(e);
            }).finally(() => {
                setIsLoading(false);
            })
    }

    const handleTaskDelete = (item) => {
        setIsLoading(true);
        axiosInstance.delete(constants.APQP_UPL.PROJECTS + "/" + item?._id)
            .then((resp) => {
                getAllProjectsData();
                toast.current.show({ severity: "success", summary: "Confirmed", detail: "Deleted Successfully", life: 3000 });
            }).catch((e) => {
                toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
                console.error(e);
            }).finally(() => {
                setIsLoading(false);
            })
    }

    const confirm = (item) => {
        confirmDialog({
            message: 'Do you want to delete this record?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            accept: () => handleTaskDelete(item),
            reject,
            acceptClassName: "btn-black",
            rejectClassName: "btn-yellow",
            draggable: false,
        });
    };

    const customButtonTemplate = (item) => {
        return (
            <div className='flex justify-content-end gap-2'>
                {item?.projectStatus === "Open" ?
                    <Button className="btn-black mx-2" label="Start" onClick={() => handleStart(item, "In-Progress")} />
                    : item?.projectStatus === "In-Progress" ?
                        <Button className="btn-black mx-2" label="Stop" onClick={() => handleStart(item, "Hold")} />
                        : item?.projectStatus === "Hold" ?
                            <Button className="btn-black mx-2" label="Start" onClick={() => handleStart(item, "In-Progress")} /> : null
                }
                <div className='flex text-center align-items-center justify-content-center'><img src={pencil} onClick={() => {setAddProjects(true);  setProjectEditValue(item)}} className='hover-bg-color' /></div>
                <div className='flex text-center align-items-center justify-content-center'><img src={view} onClick={() => handleView(item)} className='hover-bg-color' /></div>
                <div className='flex text-center align-items-center justify-content-center'><img src={trash} onClick={() => confirm(item)} className='hover-bg-color' /></div>
            </div>
        );
    };

    const formatDate = (value) => {
        var date = value ? new Date(value) : null;
        return date?.toLocaleDateString('en-US', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
        });
    }

    const exportExcel = async () => {
        setIsLoading(true);
            try {
                const response = await axios.get(constants.URL.ALL_PROJECT_DOWNLOAD, {
                    headers: getHeaders(),
                    responseType: "blob",
                });                  
              const url = URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", "All Project.xlsx");
        
              document.body.appendChild(link);
              link.click();
              URL.revokeObjectURL(url);
            } catch (error) {
              console.error("Error downloading file:", error);
            } finally {
                setIsLoading(false);
            };
    }

    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const [clientList, setClientList] = useState();
    const [userList, setUserList] = useState();
    const productionLocation = ["India", "France", "Tunisia", "Macedonia", "Mexico", "China"];
    const projectObject = ["Creation", "Modification", "Duplication", "Transfer"];
    const setFilterProjects = useStoreActions((actions) => actions.projectModel.setFilterProjects);
    const filterProjects = useStoreState((actions) => actions.projectModel.filterProjects);
    // console.log(filterProjects);

    const defaultValues = { clientName: "", productionLocation: "", projectManager: "", projectObject: ""};
    const form = useForm({ defaultValues });
    const { reset, watch, setValue } = form;
    const errors = form.formState.errors;

    const getUserList = () => {
        setIsLoading(true);
        axiosInstance
          .get(constants.URL.USERS + "?page=1&limit=1000&sort_by=-created_at")
          .then((resp) => {
            setUserList(resp?.data?.data);
          })
          .catch((e) => console.error(e))
          .finally(() => {
            setIsLoading(false);
          });
      }

      const getClientData = () => {
        setIsLoading(true);
        axiosInstance
            .get(constants.APQP_UPL.CLIENT_REGISTRY + "?page=1&limit=1000&sort_by=-created_at")
            .then((resp) => {
                setClientList(resp?.data?.data?.map((item)=> {
                    return {
                      _id: item._id, customerName: item.customerName,
                    }
                  }));
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    }

    useEffect(() => {
        if(filterProjects){
            setValue("clientName", filterProjects?.clientName)
            setValue("productionLocation", filterProjects?.productionLocation)
            setValue("projectManager", filterProjects?.projectManager)
            setValue("projectObject", filterProjects?.projectObject)
            setValue("projectEndDate", filterProjects?.projectEndDate )
        }
    }, [filterProjects]);
      
    const CN = watch("clientName");
    const PL = watch("productionLocation");
    const PM = watch("projectManager");
    const PO = watch("projectObject");
    const YEAR = watch("projectEndDate")
    
    const [dateFilter, setDateFilter] = useState(null);

    const onValid = (data) => {
        // console.log("Form data is valid:", data);
    };

        const HoldBodyTemplate = (status) => {
            let statusStyle;
            switch (status) {
                case 'Hold':
                    statusStyle = { color: '#DF5D40', background: '#FFDBD4', border: "1px solid #FFDBD4", display: "inline" };
                    break;
            }
    
            return <span style={statusStyle} className='task-status'>{status === "Hold" ? status : ""}</span>;
        }
    
        const headerTemplate = (data) => {
            return (
                <div>
                    <span className="group-project-name" style={{ marginRight: '10px' }}>{data.projectName}</span>
                    <span className="">{HoldBodyTemplate((data?.projectStatus))}</span>
                </div>
            );
        };

    return (
        <div>
            <Toast ref={toast} />
            <ConfirmDialog />
            {!addProjects && !projectViewScreen ?
                <div>
                    <div className="flex align-items-center justify-content-between mt-2">
                        <div>
                        <h3 className="PS-title pt-2">Projects Register</h3>
                        </div>
                        <div>
                            <span className="p-input-icon-right">
                                {/* <i className="pi pi-search" /> */}
                                {/* <InputText className="mx-2" placeholder="Search by Project Name" value={globalFilterValue} onChange={onGlobalFilterChange} /> */}
                            </span>
                        <Button icon="pi pi-plus" className="btn-black" label="Project" onClick={() => {setAddProjects(true); setProjectEditValue(null)}} />
                        <Button  className="btn-black mx-2" label="Download" onClick={exportExcel} />
                            </div>
                    </div>
                        {isLoading ? 
            <ProgressBar mode="indeterminate" style={{ height: '2px' }} className='my-3'></ProgressBar> : 
            <hr className='hr-line'></hr>}
            <div className="card" style={{backgroundColor:'#F8F8F8'}}>
                <form onSubmit={form.handleSubmit(onValid)} className="error_msg">
                    <div className='flex col-12 flex-container'>
                        <div className="w-full">
                            <span className="w-full">
                                <Controller
                                    name="clientName"
                                    control={form.control}
                                    render={({ field, fieldState }) => (
                                        <Dropdown className='dropdown-input' filter options={clientList} optionLabel="customerName" optionValue='_id' value={field.value || ""} placeholder="Customer" onChange={(e) => { field.onChange(e.value); }} style={{width:'100%'}}/>
                                    )}
                                />
                            </span>
                        </div>
                        <div className="w-full">
                            <span className="w-full">
                                <Controller
                                    name="projectManager"
                                    control={form.control}
                                    render={({ field, fieldState }) => (
                                        <Dropdown filter value={field.value || ""} onChange={(e) => field.onChange(e.target.value)}
                                        options={userList} optionLabel="first_name" optionValue='first_name' placeholder="Project Manager" style={{width:'100%'}}/>
                                    )}
                                />
                               </span>
                        </div>
                        <div className="w-full">
                            <span className="w-full">
                                <Controller
                                    name="productionLocation"
                                    control={form.control}
                                    render={({ field, fieldState }) => (
                                        <Dropdown id='productionLocation' options={productionLocation} value={field.value || ""} placeholder="Production Location"
                                         onChange={(e) => field.onChange(e.target.value)} style={{width:'100%'}}/>
                                    )}
                                />
                                </span>
                        </div>
                        <div className="w-full">
                            <span className="w-full">
                                <Controller
                                    name="projectObject"
                                    control={form.control}
                                    render={({ field, fieldState }) => (
                                        <Dropdown options={projectObject} value={field.value || ""} placeholder="Object"
                                         onChange={(e) => field.onChange(e.target.value)} style={{width:'100%'}} />
                                    )}
                                />
                                </span>
                        </div>
                        <div className="w-full">
                        <Calendar className="w-full" value={dateFilter} onChange={(e) => setDateFilter(e.target.value)} placeholder="Filter by SOP Year" showIcon view="year" dateFormat="yy" />
                        </div>
                        <div className='project_search'>
                            <Button className="btn-black mt-1" label="Clear" onClick={() => {form.reset({ CN, PM, PL, PO, setDateFilter },{ keepErrors: false, }); setFilterProjects(null);getAllProjectsData(); setDateFilter(null); }}/>
                            {/* <Button size="small" loading={isLoading} label="Search" className="btn-yellow" /> */}
                        </div>
                    </div>
                </form>
            </div>
                    <div className="grid mt-3">
                        <div className="col-12"> 
                            <DataTable tableStyle={{ minWidth: '50rem' }} value={records?.filter((item) => {
                                const projectManagerMatch = PM ? item?.projectManager === PM : true;
                                const productionLocationNameMatch = PL ? item?.productionLocation === PL : true;
                                const clientNameMatch = CN ? item?.client_id?._id === CN : true; 
                                const projectObjectNameMatch = PO ? item?.projectObject === PO : true;
                                const projectEndDateMatch = YEAR ? new Date(item?.projectEndDate).getFullYear() === YEAR : true;
                                const sopMatch = dateFilter ? new Date(item?.startOfProduction)?.getFullYear() === (dateFilter?.getFullYear()) : true;
                                // const status = filterProjects?.cardValue ? ((filterProjects?.cardValue === "Completed") ? (item?.projectStatus === "Completed") : (item?.projectStatus !== "Completed")) : true;
                                const status = 
                                filterProjects && filterProjects?.cardValue !== "" ? ((filterProjects?.cardValue === "In-Progress") ? ((item?.projectStatus === "In-Progress") || (item?.projectStatus === "Open")) : 
                                 (filterProjects?.cardValue === "Hold") ? (item?.projectStatus === "Hold") : (item?.projectStatus === "Completed") ) :
                                true;
                                return sopMatch && projectManagerMatch && productionLocationNameMatch && projectObjectNameMatch && clientNameMatch && projectEndDateMatch && status 
                            })}>
                                    <Column field="projectName" header="Project Name" body={(rowData) => (headerTemplate(rowData))} className='user-project-header' bodyClassName="admin-project-bodyColumn"> </Column>
                                    <Column field={(rowData) => rowData?.client_id?.customerName} header="Customer" className='user-project-header' bodyClassName="user-project-bodyColumn"></Column>
                                    <Column field="projectManager" header="PM" className='user-project-header' bodyClassName="user-project-bodyColumn"></Column>
                                    <Column field="startOfProduction" body={(rowData) => (<p>{formatDate(rowData.startOfProduction)}</p>)} header="SOP" className='user-project-header' bodyClassName="user-project-bodyColumn" style={{minWidth:'150px'}}></Column>
                                    <Column field="productionLocation" header="Prod Location" className='user-project-header' bodyClassName="user-project-bodyColumn"></Column>
                                    <Column field="completedPercentage" header="Completed Status" headerStyle={{display:'flex', alignItems:'center', justifyContent:'center', textAlign:'center'}}
                                    body={(rowData) => (
                                        <div className='flex align-items-center flex-column'>
                                            <div className='text-center'>
                                                {rowData?.completedPercentage !== undefined && rowData?.completedPercentage !== null ? new Intl.NumberFormat('en-US', {  minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(rowData.completedPercentage) + "%" : ""}
                                            </div>
                                            <div>
                                            <ProgressBar
                                                showValue={false}
                                                value={rowData?.completedPercentage}
                                                className='prog_bar text-center align-items-center justify-centent-center'
                                                pt={{value: {style: {background: rowData?.completedPercentage <= 33.33 ? 'yellow' : rowData?.completedPercentage <= 66.66  ? 'orange' : rowData?.completedPercentage <= 100 ? 'green' : '' } } }} />
                                            </div>
                                        </div>
                                    )}></Column>
                                    <Column field="someField" header=""  body={(rowData) => (customButtonTemplate(rowData))}/>
                            </DataTable>
                        </div>
                    </div>
                </div>
                :
                addProjects && !projectViewScreen ?
                    <div>
                        <div className="flex align-items-center mt-2 cursor-pointer" onClick={() => { setAddProjects(false); getAllProjectsData() }}>
                            <img src={back} className="back-logo mr-2" />
                            {!projectEditValue ?
                            <h3 className="PS-title m-0">Projects Register / Add Project</h3>
                            :
                            <h3 className="PS-title m-0">Projects Register / Edit </h3>}
                        </div>
                        <hr className="hr-line"></hr>
                        <AddProjects sendDataToParent={handleChildData} />
                    </div>
                    :
                    <div>
                        <ProjectDetails />
                    </div>
            }
        </div>
    )
}
export default Projects