import { action, persist} from "easy-peasy";
import { useEffect } from "react";


const projectModel = persist({
  projectViewDatas: null,
  projectViewScreen: false,
  projectEditValue: null,
  addProjects: false,
  filterProjects: null,
  userDashboardFilterProjects: null,
  subTaskProjectName: null,
  subTaskRedirect: null,
  fromDashboard: null,

  setProjectViewDatas: action((state, payload) => {
    state.projectViewDatas = payload;
  }),
  setProjectViewScreen: action((state, payload) => {
    state.projectViewScreen = payload;
  }),
  setProjectEditValue: action((state, payload) => {
    state.projectEditValue = payload;
  }),
  setAddProjects: action((state, payload) => {
    state.addProjects = payload;
  }),
  setFilterProjects: action((state, payload) => {
    state.filterProjects = payload;
  }),
  setUserDashboardFilterProjects: action((state, payload) => {
    state.userDashboardFilterProjects = payload;
  }),
  setSubTaskProjectName: action((state, payload) => {
    state.subTaskProjectName = payload;
  }),
  setSubTaskRedirect: action((state, payload) => {
    state.subTaskRedirect = payload;
  }),
  setFromDashboard: action((state, payload) => {
    state.fromDashboard = payload;
  }),
});

export default projectModel;


