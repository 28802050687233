import React, { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { Toast } from 'primereact/toast';
import { useStoreState, useStoreActions } from 'easy-peasy';
import constants from '../constants/constants';
import { Divider } from 'primereact/divider';
import axiosInstance from '../constants/axiosInstance';
import { useHistory } from 'react-router-dom';
import { ProgressBar } from 'primereact/progressbar';

const Dashboard = () => {
    const toast = useRef(null);
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [records, setRecords] = useState();
    const user = useStoreState((actions) => actions.loginModel.user);
    const setUserDashboardFilterProjects = useStoreActions((actions) => actions.projectModel.setUserDashboardFilterProjects);
    const userDashboardFilterProjects = useStoreState((actions) => actions.projectModel.userDashboardFilterProjects);


    useEffect(() => {
        getSchedule()
        setUserDashboardFilterProjects(null);
    }, []);

    const getSchedule = () => {
        setIsLoading(true);
        axiosInstance
            .get(constants.APQP_UPL.USER_DASHBOARD + "/" + user?.user?._id + "?" + `projectManager=&productionLocation=&client_id=&projectObject=`)
            .then((resp) => {
                setRecords(resp?.data?.data);
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handleFilterProject = (value) => {
        const data = {
            cardValue: value,
        }
        setUserDashboardFilterProjects(data);
        history.replace("/app/us-projects");
    }

    return (
        <>
            <Toast ref={toast} />
            <div>
                <h4 className='mb-0 user-dashboard-title'>Dashboard</h4>
            </div>
            {isLoading ?
                <ProgressBar mode="indeterminate" style={{ height: '2px' }} className='my-3'></ProgressBar>
                : <Divider />}
            <div className='grid mt-3' >
                <div className='col-12 md:col-6 lg:col-3'>
                    <div onClick={() => handleFilterProject("")} className='flex justify-content-between hod-Project'>
                        <div style={{ width: '50%' }}>
                            <div className='small-cards' >
                                <h2 className='dash_pro_stats_value'>{records?.totalTasks}</h2>
                                <h5 className='dash_pro_stats_title'>Total Task</h5>
                            </div>
                        </div>
                        <div className='hod-dash-image' style={{ width: '50%' }}>
                        </div>
                    </div>
                </div>
                <div className='col-12 md:col-6 lg:col-3'>
                    <div onClick={() => handleFilterProject("In-Progress")} className='hod-Project flex  justify-content-between'>
                        <div style={{ width: '50%' }}>
                            <div className='small-cards'>
                                <h2 className='dash_pro_stats_value'>{records?.onGoingTasks}</h2>
                                <h5 className='dash_pro_stats_title mb-0'>On Going Task</h5>
                            </div>
                        </div>

                        <div className='hod-dash2-image' style={{ width: '50%' }}>
                            {/* <img src={Icon1} alt='icon1' /> */}
                        </div>
                    </div>
                </div>
                <div className='col-12 md:col-6 lg:col-3'>
                    <div onClick={() => handleFilterProject("Completed")} className='hod-Project flex justify-content-between'>
                        <div style={{ width: '50%' }}>
                            <div className='small-cards'>
                                <h2 className='dash_pro_stats_value '>{records?.completedTasks}</h2>
                                <h5 className='dash_pro_stats_title mb-0'>Completed Task</h5>
                            </div>
                        </div>
                        <div className='hod-dash3-image' style={{ width: '50%' }}>
                            {/* <img src={Group} alt='group' /> */}
                        </div>
                    </div>
                </div>
                <div className='col-12 md:col-6 lg:col-3'>
                    <div onClick={() => handleFilterProject("Delayed")} className='hod-Project flex justify-content-between'>
                        <div style={{ width: '50%' }}>
                            <div className='small-cards'>
                                <h2 className='dash_pro_stats_value '>{records?.delayedTasks}</h2>
                                <h5 className='dash_pro_stats_title mb-0'>Delay Task</h5>
                            </div>
                        </div>
                        <div className='hod-dash4-image' style={{ width: '50%' }}>
                            {/* <img src={Group} alt='group' /> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Dashboard;