import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { useForm } from "react-hook-form";
import React, { useState, useRef, useEffect } from "react";
import profile from "../images/image001.png";
import constants from "../constants/constants";
import axios from "axios";
import { useHistory } from 'react-router-dom';
import { useStoreActions } from "easy-peasy";
import Logo from '../images/Logo (2).png';
import backgroundImage from '../images/RectangleCopy.png';

const EmailVerification = () => {
  const history = useHistory();
  const toast = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const setUser = useStoreActions((actions) => actions.loginModel.setUser);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
  };

  useEffect(() => {
    setUser(null);
    localStorage.removeItem('access-token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('isSessionExpired');
  }, []);

  const goto = (to) => {
    history.replace(to);
  };

  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = (data) => {
    const payload = {
      email: data.email,
    }
    setIsLoading(true);
    axios.post(constants.URL.EMAIL_VERIFICATION, payload)
      .then((resp) => {
        const userData = resp?.data?.data; 
      setUser(userData); 
      localStorage.setItem("userId", userData);
        localStorage.setItem('access-token', resp?.data?.data?.access_token);
        localStorage.setItem('refreshToken', resp?.data?.data?.refresh_token);
        goto("/forgot-password", { userId: userData });
        console.log(userData);
        
      }).catch((e) => {
        toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
        console.error(e);
      }).finally(() => {
        setIsLoading(false);
      })
  }

  

  return (
    <div>
      <Toast ref={toast} />

      <div className="login_container">
        <div className="col-12 md:col-6 flex justify-content-center align-items-center text-align-center">
          <div className="w-full justify-content-center align-items-center text-align-center flex">
          <div className="w-full lg:w-6">
            <div className="mb-5 pb-4">
              {/* <img src={profile} alt="profile" className="" /> */}
            </div>
            <div className="text-left mb-5">
              <div className="head text-3xl mb-3">Forgot Password</div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="login_error_msg">
              <div>
                <div className="">
                  <label htmlFor="email" className="block text-800  mb-3">Email</label>
                  <InputText id="email" type="text" className="w-full mb-4" defaultValue={""}
                    {...register("email", {
                      required: "This field is required",
                      pattern: {
                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, // Regex for email validation
                        message: "Invalid email address", // Custom error message for invalid email
                      },
                    })}
                    style={{backgroundColor:'#fffff', color:'#050505'}}
                  />
                    {errors?.email && (
                      <p className="p-error">{errors.email.type === "required" ? errors.email.message : "Invalid email address"}</p>
                    )}
                </div>
                <div className="login_btn">
                  <Button label="Submit" disabled={isLoading} className="w-full my-3 login-btn"
                  style={{backgroundColor: "#E6FB2D", color: "#323232",transition: "none",}} />
                </div>
              </div>
            </form>
            <div className="text-center email_login mt-4 " onClick={() => history.push('/')}>
              Login   
            </div>
          </div>
        </div>
        </div>

        <div className="md:col-6 hidden md:flex p-3" style={{ height: "100%" }}>
            <div class="ls-admin_bg flex justify-content-center align-items-center text-align-center">
              <div className="flex justify-content-center align-items-center text-align-center">
                {/* <span><img src={Logo} alt="logo" className="" /></span> */}
              </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;
