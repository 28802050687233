import React, { useState, useEffect } from 'react';
import constants from '../../../constants/constants';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { TabMenu } from 'primereact/tabmenu';
import { useRef } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import back from "../../../images/back.svg";
import { useForm } from "react-hook-form";
import { confirmDialog } from 'primereact/confirmdialog';
import TaskView from './TaskView';
import { Dialog } from 'primereact/dialog';
import { ProgressBar } from 'primereact/progressbar';
import { Checkbox } from 'primereact/checkbox';
import ProjectStatusCard from './ProjectStatusCard';
import trash from '../../../images/projects/Trash.svg';
import timer from '../../../images/timer.png';
import Attachment from '../../../images/projects/Attachment.svg';
import delaytimer from '../../../images/delaytimer.png';
import { Gantt, Task, ViewMode } from "gantt-task-react";
import "gantt-task-react/dist/index.css";
import axiosInstance from '../../../constants/axiosInstance';
import { InputText } from "primereact/inputtext";
import { Dropdown } from 'primereact/dropdown';
import { Controller } from 'react-hook-form';
import ProjectOverviewGanttChart from './ProjectOverviewGanttChart';
import { Calendar } from 'primereact/calendar';
import WeeklyGanttChart from './WeeklyGanttChart';

const ProjectDetails = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [addVisible, setAddVisible] = useState(false);
    const [fileVisible, setFileVisible] = useState(false);
    const [headerList, setHeaderList] = useState();
    const [records, setRecords] = useState();
    const [subTaskList, setSubTaskList] = useState();
    const [attachedFile, setAttachedFile] = useState();
    const [tochildProjectHeader, setTochildProjectHeader] = useState(null);
    const [tochildProjectHeaderSubTask, setTochildProjectHeaderSubTask] = useState(null);
    const projectViewDatas = useStoreState((actions) => actions.projectModel.projectViewDatas);
    const setProjectViewScreen = useStoreActions((actions) => actions.projectModel.setProjectViewScreen);
    const setProjectDashboardData = useStoreActions((actions) => actions.tabModel.setProjectDashboardData);
    const [taskView, setTaskView] = useState(false);
    const toast = useRef(null);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [tasks, setTasks] = useState();
    const setActiveIndex = useStoreActions((actions) => actions.tabModel.setActiveIndex);
    const activeIndex = useStoreState((actions) => actions.tabModel.activeIndex);
    const subTaskProjectName = useStoreState((actions) => actions.projectModel.subTaskProjectName);
    const setSubTaskProjectName = useStoreActions((actions) => actions.projectModel.setSubTaskProjectName);
    const subTaskRedirect = useStoreState((actions) => actions.projectModel.subTaskRedirect);
    const setSubTaskRedirect = useStoreActions((actions) => actions.projectModel.setSubTaskRedirect)
    const fromDashboard = useStoreState((actions) => actions.projectModel.fromDashboard);
    const setFromDashboard = useStoreActions((actions) => actions.projectModel.setFromDashboard);
    const [nameFilter, setNameFilter] = useState('');
    const [statusFilter, setStatusFilter] = useState('');
    const [assignToFilter, setAssignToFilter] = useState('');
    const [headerTitleFilter, setHeaderTitleFilter] = useState('');
    const [dateFilter, setDateFilter] = useState('');


    const items = [
        { label: 'Overview' },
        { label: 'Task' },
    ];


    useEffect(() => {
        // setActiveIndex(0);
        getUserList();
    }, []);

    useEffect(() => {
        getProjectSubTaskByProjectId();
        getProjectDashboard();
    }, [projectViewDatas]);

    useEffect(() => { 
        if (subTaskProjectName && subTaskRedirect && fromDashboard) {
        setActiveIndex(1);
        setHeaderTitleFilter(subTaskRedirect);
        setFromDashboard(null);
        }
        else{
            setActiveIndex(0);
        }
    }, [subTaskProjectName, subTaskRedirect]);

console.log(subTaskProjectName);

    useEffect(() => {
        if (records && records?.length > 0) {
            let result = records?.find((item => item._id === projectViewDatas?._id));
            setSubTaskList(result?.taskHeader_id);
            getProjectTaskHeader(result?.taskHeader_id);

            const allSubTasks = result?.taskHeader_id?.flatMap(taskHeader => taskHeader.subTasks.map(item => ({
                ...item,
                headerTitle: taskHeader.headerTitle
            }))
            ).filter(item => item.startDate && item.endDate);

            if (allSubTasks?.length === 0) return;

            const data = allSubTasks?.filter(item => (item.startDate && item.endDate))?.map(subTask => {
                let s = new Date(subTask.startDate).setHours(0, 0, 0, 0);
                let e = new Date(subTask.endDate).setHours(23, 59, 59, 999);

                return {
                    id: subTask?._id?._id,
                    type: "task",
                    headerTitle: subTask?.headerTitle,
                    name: subTask.subTaskName,
                    start: new Date(s),
                    end: new Date(e),
                    isDisabled: true,
                    description: 'Additional Info 2', // Extra column data
                    duration: Math.ceil(((new Date(e) - new Date(s)) + 1) / (1000 * 60 * 60 * 24)),
                    styles: {
                        backgroundColor: (subTask?.completionStatus == "Delayed" && subTask?.subTaskStatus === "Completed") ? "#BEE868" :
                            (subTask?.completionStatus == "OnTime" && subTask?.subTaskStatus === "Completed") ? "#659000" :
                                (subTask?.subTaskStatus === "Open" && (new Date(e) < new Date())) ? "#CE0303" :
                                    (subTask?.subTaskStatus === "In-Progress" && (new Date(e) < new Date())) ? "#F3E900" : "#5445E7"
                    }
                };
            });
            setTasks(data);
        }
    }, [records, projectViewDatas]);

    const getProjectDashboard = () => {
        axiosInstance
            .get(constants.APQP_UPL.PROJECTS_DASHBOARD + "/" + projectViewDatas?._id)
            .then((resp) => {
                setProjectDashboardData(resp?.data?.data);
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    }

    const getProjectTaskHeader = (data) => {
        setIsLoading(true);
        axiosInstance
            .get(constants.APQP_UPL.TASK_HEADER)
            .then((resp) => {
                setHeaderList(resp?.data?.data);

                if (data && data.length > 0) {
                    let filterData = data && data?.map((item) => { return { _id: item._id._id } });
                    let _selectedCategories = [...filterData];
                    setSelectedCategories(_selectedCategories);
                }
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    }

    const getProjectSubTaskByProjectId = () => {
        setIsLoading(true);
        axiosInstance
            .get(constants.APQP_UPL.PROJECTS)
            .then((resp) => {
                setRecords(resp?.data?.data);
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    }

    const defaultValues = { title: "", desc: "", expected_edate: "", };
    const form = useForm({ defaultValues });
    const { reset } = form;

    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    }

    const handleTaskDelete = (headerId, subTaskId) => {
        setIsLoading(true);
        axiosInstance.delete(constants.APQP_UPL.PROJECTS + "/" + projectViewDatas?._id + "/taskHeader/" + headerId + "/subtask/" + subTaskId?._id)
            .then((resp) => {
                getProjectSubTaskByProjectId();
                getProjectDashboard();
                toast.current.show({ severity: "success", summary: "Confirmed", detail: "Deleted Successfully", life: 3000 });
            }).catch((e) => {
                toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
                console.error(e);
            }).finally(() => {
                setIsLoading(false);
            })
    }

    const confirm = (item, tasks) => {
        confirmDialog({
            message: 'Do you want to delete this record?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            accept: () => handleTaskDelete(item, tasks),
            reject,
            acceptClassName: "btn-black",
            rejectClassName: "btn-yellow",
            draggable: false,
            // className: "confirm_dilog",
        });
    };

    const formatDate = (value) => {
        var date = value ? new Date(value) : null;
        return date?.toLocaleDateString('en-US', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
        });
    }

    const statusBodyTemplate = (status) => {
        let statusStyle;
        switch (status) {
            case 'Completed':
                statusStyle = { color: '#659000', background: '#ECFCC0', display: "inline" };
                break;
            case 'In-Progress':
                statusStyle = { color: '#9E00C6', background: '#F9E1FF', display: "inline" };
                break;
            case "Open":
                statusStyle = { color: '#493BD7', background: '#E2DFFF', display: "inline" };
                break;
            default:
                statusStyle = { color: 'black', background: '' };
        }

        return <div style={statusStyle} className='task-status'>{status ? status : "Open"}</div>;
    };
    const handleOpenAttachment = (item) => {
        console.log(item);
        setAttachedFile(item)
        setFileVisible(true);
    }
    const HideEdit = () => {
        setAddVisible(false);
        setFileVisible(false);
        reset();
    }

    const user = useStoreState((actions) => actions.loginModel.user);

    const onSubmit = () => {
        const payload = {
            taskHeader_id: selectedCategories?.map((item) => { return item._id }),
            taskHeaderCreatedBy: user.user._id
        }
        setIsLoading(true);
        axiosInstance.patch(constants.APQP_UPL.PROJECTS + "/" + projectViewDatas?._id, payload)
            .then((resp) => {
                toast.current.show({ severity: "success", summary: "Success", detail: "Added Successfully" });
                reset();
                setAddVisible(false);
                setFileVisible(false);
                setActiveIndex(1)
                getProjectSubTaskByProjectId();
                getProjectDashboard();
            }).catch((e) => {
                toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.error });
                console.error(e);
            }).finally(() => {
                setIsLoading(false);
            })
    }

    const onCategoryChange = (e) => {
        let _selectedCategories = [...selectedCategories];

        if (e.checked)
            _selectedCategories.push(e.value);
        else
            _selectedCategories = _selectedCategories.filter(category => category._id !== e.value._id);

        setSelectedCategories(_selectedCategories);
    };

    const EmptyTooltipContent = () => {
        return null; // Render nothing to hide the tooltip
    };

    const TaskListTable = ({ tasks, rowHeight }) => {
        return (
            <div style={{ border: "1px solid #dfe1e5", borderWidth: "0 0 1px 1px" }}>
                {tasks.map((item, i) => {
                    return (
                        <tr key={item.id} style={{ height: rowHeight, display: "flex", alignItems: "center", cursor: "auto", background: i % 2 === 0 ? "#ffffff" : "#f4f5f7", paddingLeft: 10 }}>
                            <td style={{ width: "50%" }} className='task_name'>{item.name}</td>
                            <td style={{ width: "25%" }}>{formatDate(item.start)}</td>
                            <td style={{ width: "25%" }}>{formatDate(item.end)}</td>
                        </tr>
                    );
                })}
            </div>
        );
    };

    const exportExcel = async () => {
        setIsLoading(true);
        try {
            const response = await axiosInstance.get(constants.URL.PARTICULAR_PROJECT_DOWNLOAD + "/" + projectViewDatas?._id, {
                responseType: "blob",
            });
            const url = URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Particular Project.xlsx");

            document.body.appendChild(link);
            link.click();
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error downloading file:", error);
        } finally {
            setIsLoading(false);
        };
    }

    const handleChildData = (updatedData) => {
        setTaskView(false);
        getProjectSubTaskByProjectId();
    };

    const [userList, setUserList] = useState();

    const getUserList = () => {
        setIsLoading(true);
        axiosInstance
            .get(constants.URL.USERS + "?page=1&limit=1000&sort_by=-created_at")
            .then((resp) => {
                setUserList(resp?.data?.data);
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    }



    const status = ['Open', 'In-Progress', 'Completed']

    const handleClear = () => {
        setNameFilter('');
        setStatusFilter('');
        setAssignToFilter('');
        setHeaderTitleFilter('');
        setDateFilter('');
    };

    const handleBack = () => {
        setSubTaskProjectName(null); 
        setSubTaskRedirect(null);
        setProjectViewScreen(false);
    }

    return (
        <div>
            <Toast ref={toast} />
            {!taskView ?
                <div>
                    <div className="title1 mt-2">
                        <div className='cursor-pointer' onClick={handleBack} >
                            <img src={back} className="back-logo mr-2" />
                            <span className="PS-title pt-2">Projects Register / {projectViewDatas?.projectName}</span>
                        </div>
                        <div className="btn2">
                            <div>                              
                                <Button className="btn-black mr-2" label="Add" onClick={() => setAddVisible(true)} disabled={projectViewDatas?.projectStatus !== "Open"} />
                                {/* <Button className="btn-black mr-2" label="Add" onClick={() => setAddVisible(true)} /> */}
                                <Button className="btn-black mx-2" label="Download" onClick={exportExcel} />
                            </div>
                        </div>
                    </div>
                    {isLoading ?
                    
                        <ProgressBar mode="indeterminate" style={{ height: '2px' }} className='my-3'></ProgressBar> :
                        <hr className='hr-line'></hr>}
                    <>
                        <div className="Trainee-wrapper">
                            <div className="flex justify-content-between master-tab-inner" >
                                <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
                            </div>
                            {activeIndex === 0 &&
                                <>
                                    <ProjectStatusCard />
                                    {projectViewDatas?.projectStatus === "Open" ?
                                        null :
                                        <div>
                                            {tasks ?
                                                <div>
                                                    <div className='task_progress'>Task Progress</div>
                                                    <hr className='hr-line'></hr>
                                                    <div className='flex justify-content-end align-items-center mb-3'>
                                                        <div className='open'></div><span className='status'>Open</span>
                                                        <div className='delayed_status'></div><span className='status'>Delayed Completion</span>
                                                        <div className='completed'></div><span className='status'>On Time Completed</span>
                                                        <div className='inprogress'></div><span className='status'>Delayed Inprogress</span>
                                                        <div className='delayed_open'></div><span className='status'>Delayed Open</span>
                                                    </div>
                                                    <WeeklyGanttChart toGantt={tasks} />
                                                    {/* <ProjectOverviewGanttChart toGantt={tasks} /> */}
                                                    {/* <div className="gantt-container" >
                                                        <Gantt TooltipContent={EmptyTooltipContent} viewMode={ViewMode.Week}
                                                            tasks={tasks} barFill={90} rowHeight={40}
                                                            barCornerRadius={0} listCellWidth={50} fontSize={12}
                                                            //  columnWidth={30} // Width of the date columns
                                                            TaskListHeader={({ headerHeight }) => (
                                                                <div style={{ border: "1px solid #dfe1e5", borderWidth: "1px 0 1px 1px", width: "428px" }}>
                                                                    <tr style={{
                                                                        height: headerHeight,
                                                                        marginBottom: -2, fontWeight: "bold", display: "flex", alignItems: "center", paddingLeft: 10
                                                                    }}>
                                                                        <td style={{ width: "50%" }} className='task_name'>Name</td>
                                                                        <td style={{ width: "25%" }}>From</td>
                                                                        <td style={{ width: "25%" }}>To</td>
                                                                    </tr>
                                                                </div>
                                                            )}

                                                            TaskListTable={(props) => (<TaskListTable {...props} />)}
                                                        />
                                                    </div> */}
                                                </div>
                                                : null}
                                        </div>}
                                </>

                            }
                            {activeIndex === 1 && <>
                                <div className="card" style={{ backgroundColor: '#F8F8F8' }}>
                                    <div className='flex col-12 flex-container'>
                                        <div className="w-full">
                                            <InputText className="w-full" value={headerTitleFilter} onChange={(e) => setHeaderTitleFilter(e.target.value)} placeholder="Filter by Header Title" />
                                        </div>
                                        <div className="w-full">
                                            <InputText className="w-full" value={nameFilter} onChange={(e) => setNameFilter(e.target.value)} placeholder="Filter by Task Name" />
                                        </div>
                                        <div className="w-full">
                                            <span className="w-full">
                                                <Controller
                                                    name="clientName"
                                                    control={form.control}
                                                    render={({ field }) => (
                                                        <Dropdown filter value={assignToFilter} onChange={(e) => setAssignToFilter(e.target.value)} placeholder="Filter by Assigned To" options={userList} optionLabel="first_name" optionValue="first_name" style={{ width: '100%' }} />
                                                    )}
                                                />
                                            </span>
                                        </div>
                                        <div className="w-full">
                                            <span className="w-full">
                                                <Controller
                                                    name=""
                                                    control={form.control}
                                                    render={({ field }) => (
                                                        <Dropdown filter value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)} placeholder="Filter by Status" options={status} style={{ width: '100%' }} />
                                                    )} />
                                            </span>
                                        </div>
                                        <div className="w-full">
                                            <Calendar className="w-full" value={dateFilter} onChange={(e) => setDateFilter(e.value)} placeholder="Filter by Date" showIcon dateFormat="MM dd yy" />
                                        </div>
                                        <div className='project_search'>
                                            <Button className="btn-black mt-1" label="Clear" onClick={handleClear} />
                                        </div>
                                    </div>
                                </div>
                                <div>

                                    {subTaskList
                                        ?.filter((item) => {
                                            return headerTitleFilter
                                                ? item?.headerTitle?.toLowerCase().includes(headerTitleFilter.toLowerCase())
                                                : true;
                                        })
                                        ?.map((item) => {
                                            const completedCount = item?.subTasks?.filter((task) => task.subTaskStatus === "Completed");

                                            const filteredSubTasks = item?.subTasks?.filter((task) => {
                                                return (
                                                    (nameFilter ? task?.subTaskName.toLowerCase().includes(nameFilter.toLowerCase()) : true) &&
                                                    (statusFilter ? task?.subTaskStatus === statusFilter : true) &&
                                                    (assignToFilter ? task?.assignTo?.first_name.toLowerCase().includes(assignToFilter.toLowerCase()) : true) &&
                                                    (dateFilter ? new Date(task?.endDate).toDateString() === new Date(dateFilter).toDateString() : true)
                                                );
                                            });

                                            if (filteredSubTasks.length === 0) {
                                                return (
                                                    <div key={item?.id || Math.random()} className="my-4">

                                                    </div>
                                                );
                                            }


                                            return (
                                                <div key={item?.id || Math.random()}>

                                                    <div className="table-wrapper mt-4" >
                                                        <div className="card-title flex xl:flex-row xl:align-items-center px-4 border-bottom-1 border-300 ">
                                                            <div className='flex flex-row align-items-center text-align-center col-12 flex-1'>
                                                                <div className="card-heading m-0 col-2">{item?.headerTitle}</div>
                                                                <div className="card-heading m-0 col-2 text-center flex align-items-center justify-content-center">
                                                                    <span class="border-circle p-1 m-2 font-bold" style={{ background: "#26C10D" }}></span><span>On Time</span>
                                                                </div>
                                                                <div className="card-heading m-0 col-2 text-center">
                                                                    <div className='mx-4 justify-content-center align-items-center text-align-center flex flex-column'>{(item?.subTaskscompletedPercentage).toFixed()}%
                                                                        <ProgressBar showValue={false} value={item?.subTaskscompletedPercentage} className='prog_bar' pt={{ value: { style: { background: item?.subTaskscompletedPercentage <= 33.33 ? 'yellow' : item?.subTaskscompletedPercentage <= 66.66 ? 'orange' : item?.subTaskscompletedPercentage <= "100" ? 'green' : '' } } }}></ProgressBar>
                                                                    </div></div>
                                                                <div className="card-heading m-0 col-2 text-center">Task  {completedCount && completedCount?.length > 0 ? completedCount?.length : 0} / {item?.subTasksCount}</div>
                                                                <div className="card-heading m-0 col-2 text-center">Start Date</div>
                                                                <div className="card-heading m-0 col-2 text-center">End Date</div>
                                                            </div>
                                                            <div className=' justify-content-end flex  align-items-center text-align-center' style={{ visibility: 'hidden' }}  >
                                                                <div>< img src={trash} onClick={() => confirm()} /></div>
                                                                <div>{tasks?.uploadAttachment && tasks.uploadAttachment.length > 0 ? <img src={Attachment} /> : <img src={Attachment} />}</div>
                                                            </div>
                                                        </div>
                                                        {filteredSubTasks?.map((tasks) => {

                                                            // const completedSubtaskCount = Math.round((subTaskscompletedPercentage / 100) * subTasksCount);
                                                            return (
                                                                <div className="flex xl:flex-row xl:align-items-center px-4  border-bottom-1 border-300 ">
                                                                    <div className="flex-row align-items-center text-align-center col-12 flex flex-1 " onClick={() => { setTaskView(true); setTochildProjectHeader(item); setTochildProjectHeaderSubTask(tasks) }}>
                                                                        {/* <div className=' align-items-center'  onClick={() => { setTaskView(true); setTochildProjectHeader(item); setTochildProjectHeaderSubTask(tasks) }}> */}
                                                                        <div className="flex flex-column align-items-center sm:align-items-start gap-3 col-2">
                                                                            <div className="text-2xl font-bold text-900">{tasks?.subTaskName}</div>
                                                                        </div>
                                                                        <div className="text-2xl font-bold text-900 col-2 text-center">{statusBodyTemplate(tasks?.subTaskStatus)}</div>
                                                                        <div className="text-2xl font-bold text-900 col-2 text-center flex align-items-center justify-content-center">
                                                                            <span className={tasks?.completionStatus === "Delayed" ? 'delayed' : tasks?.completionStatus === "OnTime" ? 'ontime' : ''}> {tasks?.completionStatus === "Delayed" ? <img src={delaytimer} style={{ paddingRight: '3px' }} /> : tasks?.completionStatus === "OnTime" ? <img src={timer} style={{ paddingRight: '3px' }} /> : ''} {tasks?.completionStatus}</span>
                                                                        </div>
                                                                        <div className="text-2xl font-bold text-900 col-2 text-center">{tasks?.assignTo?.first_name}</div>
                                                                        <div className="text-2xl font-bold text-900 col-2 text-center">{formatDate(tasks?.startDate)}</div>
                                                                        <div className="text-2xl font-bold text-900 col-2 text-center">{formatDate(tasks?.endDate)}</div>
                                                                        {/* </div> */}

                                                                    </div>

                                                                    <div className='flex align-items-center text-align-center'>
                                                                        <div>
                                                                            {tasks?.uploadAttachment?.length > 0 ?
                                                                                <div onClick={() => { handleOpenAttachment(tasks?.uploadAttachment); }}><img src={Attachment} className='hover-bg-color' /></div>
                                                                                : <img src={Attachment} style={{ visibility: 'hidden' }} />}
                                                                        </div>
                                                                        <div>< img src={trash} onClick={() => confirm(item?._id?._id, tasks?._id)} className='hover-bg-color' /></div>


                                                                    </div>

                                                                    {/* </div> */}
                                                                </div>
                                                            )

                                                        })}
                                                    </div>
                                                </div>
                                            )
                                        })}

                                    {subTaskList && subTaskList?.length === 0 ? <div className='py-3 px-5'>No Data found</div> : null}
                                </div> </>}
                        </div>


                        <Dialog header="File Attachment" visible={fileVisible} style={{ width: "30vw" }} onHide={HideEdit} breakpoints={{ '960px': '40vw', '641px': '80vw' }}>
                            {/* <div className='flex flex-column justify-content-start text-align-center'> */}
                            <div>
                                {attachedFile?.map((item, index) => {
                                    return (
                                        <div className='mb-3'>
                                            <a href={item?.location} target='_blank' style={{ fontSize: '13px', color: '#0000FF', fontWeight: '400' }}>{item?.filename}</a>
                                        </div>
                                    )
                                })}

                            </div>
                            {/* </div> */}
                        </Dialog>
                    </>
                    <Dialog header="Add Pre Defined Task" visible={addVisible} style={{ width: "30vw" }} onHide={HideEdit} breakpoints={{ '960px': '40vw', '641px': '80vw' }}>
                        <div className='flex flex-column justify-content-start text-align-center'>
                            <div className="flex flex-column gap-3 m-3">
                                {headerList?.map((category) => {
                                    return (
                                        <div _id={category._id} className="flex align-items-center">
                                            <Checkbox inputId={category._id} name="category" value={category} onChange={onCategoryChange} checked={selectedCategories.some((item) => item._id === category._id)} />
                                            <label htmlFor={category._id} className="ml-2">
                                                {category.headerTitle}
                                            </label>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                        <div className="flex justify-content-end align-items-center">
                            <Button className="btn-yellow" loading={isLoading} style={{ minWidth: '3rem' }} onClick={onSubmit}>{subTaskList && subTaskList?.length > 0 ? "Update" : "Add"}</Button>
                        </div>
                    </Dialog>
                </div>
                :
                <div>
        <div className="title1 cursor-pointer mt-2" onClick={() => {setTaskView(false); getProjectSubTaskByProjectId(); }}>
                        <img src={back} className="back-logo mr-2" />
                        <span className="PS-title pt-2">Projects Register / {projectViewDatas?.projectName} / {tochildProjectHeader?.headerTitle} / {tochildProjectHeaderSubTask?.subTaskName}</span>
                    </div>
                    <hr className="hr-line"></hr>
                    <TaskView headerDetails={tochildProjectHeader} subTaskDetail={tochildProjectHeaderSubTask} sendDataToParent={handleChildData} />
                </div> 
            }
        </div>
    )
}

export default ProjectDetails