import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useEffect, useRef, useState } from 'react';
import telegram from '../../../images/telegram.svg';
import constants from '../../../constants/constants';
import getHeaders from '../../../controllers/utils';
import axios from 'axios';
import { useStoreState } from 'easy-peasy';
import { Toast } from 'primereact/toast';
import axiosInstance from '../../../constants/axiosInstance';

function Comments(props) {
    console.log(props.tocomments);
    const data = props.tocomments;
    const toast = useRef(null);
    const [value, setValue] = useState('');
    const [records, setRecords] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const user = useStoreState((actions) => actions.loginModel.user);
    const projectViewDatas = useStoreState((actions) => actions.projectModel.projectViewDatas);

    useEffect(() => {
        getComments();
    }, []);

    const getComments = () => {
        axiosInstance.get(constants.APQP_UPL.PROJECTS + "/" + projectViewDatas?._id)
            .then((resp) => {
                // const comments = 
                const filteredSubTasks = [];
                resp?.data?.data?.forEach(project => {
                  project.taskHeader_id.forEach(header => {
                    header.subTasks.forEach(subTask => {
                      if (subTask._id._id === data?.subTaskDetail?._id?._id) {
                        filteredSubTasks.push(subTask);
                      }
                    });
                  });
                });
                console.log(filteredSubTasks[0]?.comments);
                setRecords(filteredSubTasks[0]?.comments);
            }).catch((e) => {
                console.error(e);
            }).finally(() => {
                setIsLoading(false);
            })
    }

    const handlecomments = () => {
        const payload = {
            comments: [{
                user_id: user?.user?._id,
                text: value,
                chat_id: user?.user?._id
            }]
        }
        setIsLoading(false);
        axiosInstance.patch(constants.APQP_UPL.PROJECTS + "/" + projectViewDatas?._id + "/taskHeader/" + data?.headerDetails?._id?._id + "/subtask/" + data?.subTaskDetail?._id?._id, payload)
        .then((resp) => {
                getComments();
                setValue("");
            }).catch((e) => {
                console.error(e);
                toast.current.show({ severity: "error", summary: "Failure", detail: e?.message });
            }).finally(() => {
                setIsLoading(false);
            })
    }
    
    const formatDate = (value) => {
        var date = new Date(value);
        return date?.toLocaleDateString('en-US', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
        });
    }

    return (
        <>
            <Toast ref={toast} />
            {records && records?.length == 0 ? <div className='pb-3 px-2'>No comments found</div> : null}
            {records?.map((item) => {
                return (
                    <>
                        {item?.chat_id?.first_name === user?.user?.first_name ?
                            <div className='my-2 flex flex-column align-items-end'>
                                <div className="flex align-items-center mb-3">
                                    <span className='text-13-500'>{item?.chat_id?.first_name}</span>
                                    <span className='text-12-400 px-3'>{formatDate(item?.createdAt)}</span>
                                    <Avatar label={item?.chat_id?.first_name?.charAt(0)} size="large" style={{ backgroundColor: '#FFD4D4', color: '#2D2D2D' }} shape="circle" />
                                </div>
                                <span className='text-12-400 comment-lefttext-bg'>{item?.text}</span>
                            </div>
                            :
                            <div className='my-2 flex flex-column align-items-start'>
                                <div className="flex align-items-center mb-3">
                                    <Avatar label={item?.chat_id?.first_name?.charAt(0)} size="large" style={{ backgroundColor: '#F7FFB4', color: '#2D2D2D' }} shape="circle" />
                                    <span className='text-13-500 px-3'>{item?.chat_id?.first_name}</span>
                                    <span className='text-12-400'>{formatDate(item?.createdAt)}</span>
                                </div>
                                <span className='text-12-400 comment-righttext-bg'>{item?.text}</span>
                            </div>
                        }
                    </>
                )
            })}

            <div className='flex mt-3'>
                <InputText value={value} onChange={(e) => setValue(e.target.value)} className='w-full border-none border-noround-right comment-input' />
                <Button aria-label="Submit" onClick={handlecomments} className='btn-yellow border-noround-left' disabled={!value}>
                    <img src={telegram} />
                </Button>
            </div>
        </>
    )
}

export default Comments