import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axios from "axios";
import { useStoreActions, useStoreState } from "easy-peasy";
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Button } from "primereact/button";
import constants from "../../../constants/constants";
import getHeaders from "../../../controllers/utils";
import axiosInstance from "../../../constants/axiosInstance";
import pencil from '../../../images/projects/Pencil.svg';
import trash from '../../../images/projects/Trash.svg';


const AllClientsList = () => {
    const toast = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [records, setRecords] = useState();
    const setActiveIndex = useStoreActions((actions) => actions.tabModel.setActiveIndex);
    const setEmployeeEditValue = useStoreActions((actions) => actions.tabModel.setEmployeeEditValue);
    const setFilterProjects = useStoreActions((actions) => actions.projectModel.setFilterProjects);

    useEffect(() => {
        getUserList();
        setEmployeeEditValue(null);
        setFilterProjects(null);
    }, []);

    const getUserList = () => {
        setIsLoading(true);
        axiosInstance
            .get(constants.APQP_UPL.CLIENT_REGISTRY + "?page=1&limit=1000&sort_by=-created_at")
            .then((resp) => {
                setRecords(resp?.data?.data);
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handleTaskDelete = (item) => {
        setIsLoading(true);
        axiosInstance.delete(constants.APQP_UPL.CLIENT_REGISTRY + "/" + item?._id)
            .then((resp) => {
                // console.log(resp?.data?.data);
                getUserList();
                toast.current.show({ severity: "success", summary: "Confirmed", detail: "Deleted Successfully", life: 3000 });
            }).catch((e) => {
                toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
                console.error(e);
            }).finally(() => {
                setIsLoading(false);
            })
    }

    const confirm = (item) => {
        console.log(item);
        confirmDialog({
            message: 'Do you want to delete this record?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            accept: () => handleTaskDelete(item),
            acceptClassName: "btn-black",
            rejectClassName: "btn-yellow",
            draggable: false,
            // className: "confirm_dilog",
        });
    };

    const IconBodyTemplate = (item) => {
        return (
            // <div className='flex justify-content-end gap-2'>
            //     <Button className="btn-black" label="Edit" onClick={() => {setActiveIndex(0); setEmployeeEditValue(item)}} />
            //     <Button className="btn-black" label="Delete" onClick={() => confirm(item)} />
            // </div>
            <div className='flex justify-content-end text-center gap-2'>
            <div>< img src={pencil} onClick={() => {setActiveIndex(0); setEmployeeEditValue(item)}}/></div>
            <div>< img src={trash} onClick={() => confirm(item)} /></div>
        </div>
        );
    };

    const statusBodyTemplate = (item) => {
        let statusStyle;
        switch (item.status) {
            case false:
                statusStyle = { color: '#486600', background: '#ECFCC0' };
                break;
            case true:
                statusStyle = { color: '#DF5D40', background: '#FFDBD4' };
                break;
            default:
                statusStyle = { color: 'black', background: '#F8F8F8' };
        }
        return <div style={statusStyle} className='task-status'>{item.status === false ? "Active" : "In Active"}</div>;
    };

    return (
        <div>
            <Toast ref={toast} />
            <ConfirmDialog />
            <div className="grid">
                <div className="col-12">
                    <div className="">
                        <DataTable tableStyle={{ minWidth: '50rem' }} value={records}>
                            <Column field="customerName" header="Customer Name" className='user-project-header' bodyClassName="user-project-bodyColumn"></Column>
                            <Column field="country" header="Country" className='user-project-header' bodyClassName="user-project-bodyColumn"></Column>
                            <Column field="legalEntity" header="Legal Entity" className='user-project-header' bodyClassName="user-project-bodyColumn"></Column>
                            <Column field="incoterm" header="Incoterm" className='user-project-header' bodyClassName="user-project-bodyColumn"></Column>
                            <Column field="vatNumber" header="VAT Number" className='user-project-header' bodyClassName="user-project-bodyColumn"></Column>
                            <Column field="someField" header="" body={(rowData) => (IconBodyTemplate(rowData))} className='user-project-header' bodyClassName="user-project-bodyColumn"/>
                        </DataTable>
                            
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AllClientsList;
