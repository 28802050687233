// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gantt_container {
    border-radius: 8px;
    border: 1px solid #EAEAEA;
}

.gantt_grid_head_cell {
    padding: 0 10px;
    text-align: left !important;
    border-right: 0px !important;
}

.gantt_tree_icon.gantt_blank {
    width: 0px !important;
}

.gantt_tree_content {
    font-size: 12px !important;
    font-weight: 400;
    letter-spacing: 0.01px !important;
    color: #03030A !important;
}

/* Make all header cells bold */
.gantt_grid_scale,
.gantt_task_scale {
    background-color: #F8F8F8 !important;
    /* Set background color */
    color: #03030A !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    letter-spacing: 0.01px !important;
}

.bold-header {
    font-size: 12px !important;
}

.gantt_scale_cell {
    border-right: 1px solid #DCDCDC !important;
    font-size: 11px;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/WeeklyGanttChart.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,eAAe;IACf,2BAA2B;IAC3B,4BAA4B;AAChC;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,0BAA0B;IAC1B,gBAAgB;IAChB,iCAAiC;IACjC,yBAAyB;AAC7B;;AAEA,+BAA+B;AAC/B;;IAEI,oCAAoC;IACpC,yBAAyB;IACzB,yBAAyB;IACzB,0BAA0B;IAC1B,2BAA2B;IAC3B,iCAAiC;AACrC;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,0CAA0C;IAC1C,eAAe;AACnB","sourcesContent":[".gantt_container {\n    border-radius: 8px;\n    border: 1px solid #EAEAEA;\n}\n\n.gantt_grid_head_cell {\n    padding: 0 10px;\n    text-align: left !important;\n    border-right: 0px !important;\n}\n\n.gantt_tree_icon.gantt_blank {\n    width: 0px !important;\n}\n\n.gantt_tree_content {\n    font-size: 12px !important;\n    font-weight: 400;\n    letter-spacing: 0.01px !important;\n    color: #03030A !important;\n}\n\n/* Make all header cells bold */\n.gantt_grid_scale,\n.gantt_task_scale {\n    background-color: #F8F8F8 !important;\n    /* Set background color */\n    color: #03030A !important;\n    font-size: 14px !important;\n    font-weight: 500 !important;\n    letter-spacing: 0.01px !important;\n}\n\n.bold-header {\n    font-size: 12px !important;\n}\n\n.gantt_scale_cell {\n    border-right: 1px solid #DCDCDC !important;\n    font-size: 11px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
