import React, { useState, useEffect } from 'react';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import constants from "../../constants/constants";
import { useStoreState } from "easy-peasy";
import { Divider } from 'primereact/divider';
import axiosInstance from '../../constants/axiosInstance';
import { useStoreActions } from 'easy-peasy';

const FileViewer = () => {
    const [isLoading, setIsLoading] = useState(false);
    const user = useStoreState((actions) => actions.loginModel.user);
    const [records, setRecords] = useState();
    const setUserDashboardFilterProjects = useStoreActions((actions) => actions.projectModel.setUserDashboardFilterProjects);

    useEffect(() => {
        getFileViewerData();
        setUserDashboardFilterProjects(null);
    }, []);

    const getFileViewerData = () => {
        setIsLoading(true);
        axiosInstance.get(constants.URL.GET_HOD_FILE_VIEW + "/" + user?.user?._id) 
            .then((resp) => {
                setRecords(resp?.data?.data);
            }).catch((e) => {
                console.error(e?.response?.data?.message);
                const msg = e?.response?.data?.message
                setRecords(msg === "No aprreciation awards found" ? [] : null);
            }).finally(() => {
                setIsLoading(false);
            })
    }
    return(
        <div>
            <ConfirmDialog />
            <div>
                <h3 className="mb-0 user-dashboard-title">File Viewer</h3>
                </div>  
                 <Divider />
                <div className='PS-title-bottomline'>
                <DataTable tableStyle={{ minWidth: '50rem', width: '100%', border: '1px solid #EAEAEA', borderRadius:'8px' }} value={records} >
                    <Column field="projectName" header="Project Name" className='group-project-name' bodyClassName="file-viewer-bodyColumn" style={{ width: '25%'}}></Column>
                    <Column field={(rowData) => rowData.taskHeader_id?.[0]?.headerTitle} header="Task Header" className='group-project-name' bodyClassName="file-viewer-bodyColumn" style={{ width: '25%'}}></Column>
                    <Column field={(rowData) => rowData.taskHeader_id?.[0]?.subTasks?.[0]?.subTaskName} header="Sub Task " className='group-project-name' bodyClassName="file-viewer-bodyColumn" style={{ width: '25%'}}></Column>
                    <Column header="File Name" style={{ width: '25%'}} className='group-project-name' bodyStyle={{ color: '#0000FF', fontSize:'13px', fontWeight: '400' }} 
                        body={(rowData) => {
                            const attachment = rowData.taskHeader_id?.[0]?.subTasks?.[0]?.uploadAttachment?.[0];
                            return attachment ? (
                                <a href={attachment.location} target="_blank" className="">
                                    {attachment.filename}
                                </a>
                            ) : null;
                        }} />
                </DataTable>
                </div>
            </div>
    );
};

export default FileViewer