import React, { useEffect } from "react";
import { useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Controller, useForm } from "react-hook-form";
import classNames from "classnames";
import constants from "../../constants/constants";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import axiosInstance from "../../constants/axiosInstance";

const DepartmentAdd = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [departmentList, setDepartmentList] = useState();
    const toast = useRef(null);

    useEffect(() => {
        getDepartment();
    }, []);

    const getDepartment = () => {
        setIsLoading(true);
        axiosInstance
            .get(constants.URL.METADATA_DEPARTMENT)
            .then((resp) => {
                setDepartmentList(resp?.data[0]?.department);
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    }

    const onSubmit = (data) => {
        // console.log(data);
        const payload = {
            department: data?.department,
        }

        setIsLoading(true);
        axiosInstance.post(constants.URL.METADATA_DEPARTMENT, payload)
            .then((resp) => {
                // console.log(resp);
                toast.current.show({ severity: "success", summary: "Success", detail: "Department Added Successfully" });
                reset();
                getDepartment();
            }).catch((e) => {
                toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
                console.error(e);
            }).finally(() => {
                setIsLoading(false);
            })
    };

    const defaultValues = { department: "" };
    const form = useForm({ defaultValues });
    const { reset, setValue } = form;
    const errors = form.formState.errors;
    const getFormErrorMessage = (name) => {
        return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
    };
    
    const handleDelete = (data) => {
        setIsLoading(true);
        axiosInstance.delete(constants.URL.METADATA_DEPARTMENT + "/" + data)
            .then((resp) => {
                getDepartment();
                toast.current.show({ severity: "success", summary: "Confirmed", detail: "Deleted Successfully", life: 3000 });
            }).catch((e) => {
                toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.message });
                console.error(e);
            }).finally(() => {
                setIsLoading(false);
            })
    }

    const confirm = (item) => {
        confirmDialog({
            message: 'Do you want to delete this Department?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            accept: () => handleDelete(item),
            // reject,
            acceptClassName: "btn-black",
            rejectClassName: "",
            draggable: false,
            // className: "confirm_dilog",
        });
    };

    return (
        <>
            <Toast ref={toast} />
            <ConfirmDialog />

            <form onSubmit={form.handleSubmit(onSubmit)} className="error_msg">
                <div className="card p-0">
                    <div className="card-title px-4 py-3">
                        <h4 className="card-heading mb-0">Add Department</h4>
                    </div>
                    <div className="p-fluid formgrid grid lr-gap p-4">
                        <div className="field col-12 md:col-6 lg:col-6">
                            <label className="col-6 fsv-label" htmlFor="department">
                                Department<span className="p-error">*</span>
                            </label>
                            <span className="w-full">
                                <Controller
                                    name="department"
                                    control={form.control}
                                    rules={{ required: "Department is required." }}
                                    render={({ field, fieldState }) => (
                                        <InputText id={field.name} value={field.value} className={classNames({ "p-invalid": fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />

                                    )}
                                />
                                {getFormErrorMessage("department")}</span>
                        </div>
                        <div className="field col-12 md:col-6 lg:col-6 flex justify-content-end mb-0 lg:mb-5">
                            <div className="">
                                <Button icon="pi pi-check" size="small" loading={isLoading} label="Save" className="btn-yellow" />
                            </div>
                        </div>
                    </div>

                    <div className="card-title px-4 py-3">
                        <h4 className="card-heading mb-0">All Department List</h4>
                    </div>
            <div className="mt-4">
                {departmentList ? departmentList?.map((item, index)=>{
                    return (
                        <div className='mb-2'>
                            <div className="card-hover px-4 py-3 flex justify-content-between align-items-center">
                                <h4 className="file-upload-size mb-0">{index + 1}. {item}</h4>
                                <i className="pi pi-trash" onClick={() => confirm(item)}></i>
                            </div>
                        </div>
                    )
                }) : <div className="px-4 mb-4">No Designation found</div>}
            </div>
                </div>
            </form>
        </>
    );
};
export default DepartmentAdd;
